<template>
  <IAmOverlay
    :loading="loadingGetCoach"
    :spinner-variant="'primary'"
  >
    <b-card
      class="px-1 py-75 overflow-hidden"
      no-body
    >
      <div class="text-center fw-800 text-nowrap d-flex-center">
        Toa số {{ stateCoachSelectedArray[itineraryIndex]?.coachNumber }}: {{ stateCoachSelectedArray[itineraryIndex]?.description }}
        <!-- <IAmIcon
          icon="softSeatOutline"
          class="mx-50"
        />
        <IAmIcon icon="airConditionerOutline" /> -->
      </div>

      <b-row
        class="flex-row flex-nowrap justify-content-center p-1"
        style="min-width: 330px;"
      >
        <b-col
          md="4"
          class="d-none d-md-block rounded-lg side_compartment"
        />

        <div class="d-none d-md-flex align-items-center justify-content-center px-25">
          <b-button
            v-ripple.300
            variant="light"
            class="btn-icon border"
            pill
          >
            <IAmIcon icon="chevronLeftOutline" />
          </b-button>
        </div>

        <b-col
          cols="12"
          md="10"
          class="side_compartment rounded-lg scroll-container overflow-auto px-2 py-1"
        >
          <b-alert
            v-if="coachLayout === undefined"
            show
            variant="warning"
          >
            <h4 class="text-center alert-heading">
              Vui lòng chọn toa tàu !
            </h4>
          </b-alert>

          <component
            :is="coachLayout"
            v-bind="getPropsForCurrentView"
          >
            <!-- <Layout6 /> -->
            <!-- <Layout4 /> -->
          </component>
        </b-col>

        <div class="d-none d-md-flex align-items-center justify-content-center px-25">
          <b-button
            v-ripple.300
            variant="light"
            class="btn-icon border"
            pill
          >
            <IAmIcon icon="chevronRightOutline" />
          </b-button>
        </div>

        <b-col
          md="4"
          class="d-none d-md-block rounded-lg side_compartment"
        />
      </b-row>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  computed, ref, watch, toRefs, provide,
} from '@vue/composition-api'
import {
  BCard, BButton, BRow, BCol, BAlert, BTooltip,
} from 'bootstrap-vue'

import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAlert,
    BTooltip,
    IAmOverlay,

    Layout4: () => import('@train/components/carriage-layout/layout_4.vue'),
    Layout5: () => import('@train/components/carriage-layout/layout_5.vue'),
    Layout6: () => import('@train/components/carriage-layout/layout_6.vue'),
    Layout7: () => import('@train/components/carriage-layout/layout_7.vue'),
    Layout13: () => import('@train/components/carriage-layout/layout_13.vue'),
    Layout21: () => import('@train/components/carriage-layout/layout_21.vue'),
    Layout22: () => import('@train/components/carriage-layout/layout_22.vue'),
    Layout23: () => import('@train/components/carriage-layout/layout_23.vue'),
    Layout24: () => import('@train/components/carriage-layout/layout_24.vue'),
    Layout25: () => import('@train/components/carriage-layout/layout_25.vue'),
    Layout26: () => import('@train/components/carriage-layout/layout_26.vue'),
    Layout27: () => import('@train/components/carriage-layout/layout_27.vue'),
    Layout29: () => import('@train/components/carriage-layout/layout_29.vue'),
    Layout30: () => import('@train/components/carriage-layout/layout_30.vue'),
  },
  props: {
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      loading,
      tabIndex,
      getBookingCode,
      stateTrainSelectedArray,
      stateCoachSelectedArray,
    } = useTrainHandle()

    const { itineraryIndex } = toRefs(props)
    const loadingGetCoach = ref(false)
    const coachLayout = ref()

    const existLayout = ['Layout4', 'Layout5', 'Layout6', 'Layout7', 'Layout13', 'Layout21', 'Layout22', 'Layout23', 'Layout24', 'Layout25', 'Layout26', 'Layout27', 'Layout29', 'Layout30']

    const payload = ref()

    const getPropsForCurrentView = ref({
      itineraryIndex: itineraryIndex.value,
    })

    async function handleGetDataCoach() {
      // console.log('handleGetDataCoach')
      try {
        loadingGetCoach.value = true
        coachLayout.value = `Layout${stateCoachSelectedArray.value[itineraryIndex.value].layoutId}`

        // FIXME: seatmap call thành công lưu vào mảng chuyến bay ban đầu
        const res = await store.dispatch('app-train/fetchSeatByCoach', payload.value)

        getPropsForCurrentView.value.availSeats = res.mainSeats
      } catch (error) {
        console.error({ error })
      } finally {
        loadingGetCoach.value = false
      }
    }

    watch(() => stateCoachSelectedArray.value, newVal => { // TODO: ĐM đang bị call 2 lần vì trong v-for searchArray chưa lấy theo tabIndex
      if (newVal[itineraryIndex.value]?.layoutId && !loadingGetCoach.value && itineraryIndex.value === tabIndex.value) {
        // console.log({ newVal })
        payload.value = {
          trainId: stateTrainSelectedArray.value[itineraryIndex.value].id,
          coachId: newVal[itineraryIndex.value].id,
          bookingCode: getBookingCode.value,
        }
        handleGetDataCoach()
      } else {
        coachLayout.value = undefined
      }
    }, { immediate: true }) // immediate case init data

    return {
      loadingGetCoach,
      existLayout,
      coachLayout,
      getPropsForCurrentView,
      stateCoachSelectedArray,
    }
  },
}
</script>

  <style lang="scss">
  /* Custom scrollbar cho Chrome, Safari */
  .scroll-container::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
  }

  .scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  .scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* Custom scrollbar cho Firefox */
  .scroll-container {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .side_compartment {
    border: 8px solid #ccc;
    // border-radius: ;
  }
  </style>

var render = function () {
  var _vm$stateCoachSelecte, _vm$stateCoachSelecte2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingGetCoach,
      "spinner-variant": 'primary'
    }
  }, [_c('b-card', {
    staticClass: "px-1 py-75 overflow-hidden",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "text-center fw-800 text-nowrap d-flex-center"
  }, [_vm._v(" Toa số " + _vm._s((_vm$stateCoachSelecte = _vm.stateCoachSelectedArray[_vm.itineraryIndex]) === null || _vm$stateCoachSelecte === void 0 ? void 0 : _vm$stateCoachSelecte.coachNumber) + ": " + _vm._s((_vm$stateCoachSelecte2 = _vm.stateCoachSelectedArray[_vm.itineraryIndex]) === null || _vm$stateCoachSelecte2 === void 0 ? void 0 : _vm$stateCoachSelecte2.description) + " ")]), _c('b-row', {
    staticClass: "flex-row flex-nowrap justify-content-center p-1",
    staticStyle: {
      "min-width": "330px"
    }
  }, [_c('b-col', {
    staticClass: "d-none d-md-block rounded-lg side_compartment",
    attrs: {
      "md": "4"
    }
  }), _c('div', {
    staticClass: "d-none d-md-flex align-items-center justify-content-center px-25"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.300",
      modifiers: {
        "300": true
      }
    }],
    staticClass: "btn-icon border",
    attrs: {
      "variant": "light",
      "pill": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronLeftOutline"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "side_compartment rounded-lg scroll-container overflow-auto px-2 py-1",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_vm.coachLayout === undefined ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('h4', {
    staticClass: "text-center alert-heading"
  }, [_vm._v(" Vui lòng chọn toa tàu ! ")])]) : _vm._e(), _c(_vm.coachLayout, _vm._b({
    tag: "component"
  }, 'component', _vm.getPropsForCurrentView, false))], 1), _c('div', {
    staticClass: "d-none d-md-flex align-items-center justify-content-center px-25"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.300",
      modifiers: {
        "300": true
      }
    }],
    staticClass: "btn-icon border",
    attrs: {
      "variant": "light",
      "pill": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronRightOutline"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-none d-md-block rounded-lg side_compartment",
    attrs: {
      "md": "4"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }